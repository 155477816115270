.background-new-teacher {
  height: 100vh;
  width: 100vw;
  position: absolute;
  display: flex;
  flex-direction: row;
}

.background-right-new-teacher {
  height: 100vh;
  width: 70vw;
  background-color: #F0F2F5;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
  
.content-new-teacher {
  width: 30%;
  min-width: 280px;
  border-radius: 10px;
  padding: 20px;
  background-image: linear-gradient(to bottom, var(--light-blue), var(--blue));
  filter: drop-shadow(0px 0px 6px darkgrey);
  display: flex;
  flex-direction: column;
}
  
.input-new-teacher {
  box-sizing: border-box;
  outline: none;
  border: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  width: 100%;
  border-radius: 5px;
  font-size: 16px;
  background-color: #F0F2F5;
  margin-top: 5px;
  margin-bottom: 15px;
}
  
.label-title-new-teacher {
  color: var(--text-white);
  align-self: center;
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.conteiner-image-new-teacher {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 40%;
  transition: all ease 0.5s;
}

.conteiner-arrows-new-teacher {
  display: flex;
  justify-content: center;
  gap: 2vw;
  
}

.arrow-new-teacher {
  color: var(--text-white);
  width: 1.7rem;
  height: 1.7rem;
  cursor: pointer;
  transform: scale(0.9);
  transition: all ease 0.3s;
}

.arrow-new-teacher:hover {
  transform: scale(1);
}

.person-image-new-teacher {
  width: 100%;
  cursor: pointer;
}
  
.label-new-teacher {
  color: var(--text-white);
  margin-bottom: 0.5vh;
}
  
.label-error-new-teacher {
  font-size: 14px;
  font-weight: 600;
  color: red;
  margin-bottom: 10px;
}
  
.buttons-container-new-teacher {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.button-new-teacher, .button-go-back-new-teacher {
  height: 100%;
  line-height: 40px;
  width: 100%;
  align-self: center;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: var(--text-white);
  background: #1D3557;
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  scale: 0.95;
  transition: all ease 0.2s;
}

.button-new-teacher:hover, .button-go-back-new-teacher:hover {
  scale: 1;
}
  
  
@media screen and (max-width: 480px) {
  .background-new-teacher {
    flex-direction: column;
  }
  .background-left-new-teacher {
    height: 30vh;
    width: 100vw;
  }
  .background-right-new-teacher {
    height: 70vh;
    width: 100vw;
  }
  .content-new-teacher {
    min-width: 240px;
  }
}