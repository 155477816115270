.background-page-not-found {
  background-color: #f0f0f0;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.background-page-not-found h1 {
  font-size: 3rem;
  color: #333;
}

.background-page-not-found p {
  font-size: 1.5rem;
  color: #333;
}

.button-page-not-found {
  background-image: linear-gradient(to left, var(--dark-blue), var(--blue));
  border: none;
  color: #fff;
  padding: 1vh 2vw;
  font-size: 1.1rem;
  border-radius: 14px;
  margin-top: 2vh;
  cursor: pointer;
  transform: scale(1);
  transition: all ease 0.2s;
}

.button-page-not-found:hover {
  transform: scale(1.05);
}
