.content-person-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width: 28%;
}

.image-container-person-card {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  border-radius: 360px;
  border: 3px solid #FFFFFF;
  padding: 2%;
}

.image-container-person-card-selected {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  border-radius: 360px;
  border: 3px solid var(--dark-blue);
  padding: 2%;
}

.name-person-card {
  color: var(--text-white);
  font-size: 1rem;
  font-weight: 500;
}