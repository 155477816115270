.container-game {
  position: absolute;
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  z-index: 101;
  background-color: #2f2f2f;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
