.background-home {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(to bottom, var(--text-white), #e7eaec);
  overflow: hidden;
}

.background-top-home {
  height: 10%;
  width: 100%;
  display: flex;
  flex-direction: row;
  background-image: linear-gradient(
    to right,
    var(--dark-blue),
    var(--light-blue)
  );
}

.container-logo-image-home {
  height: 100%;
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-image-home {
  height: 100px;
  width: 100px;
}

.container-hero-card-home {
  height: 100%;
  width: 80%;
  display: flex;
  justify-content: center;
}

.container-exit-home {
  height: 100%;
  width: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--text-white);
}

.exit-home {
  cursor: pointer;
  width: auto;
  height: 40px;
}

.exit-description {
  cursor: pointer;
}

.background-bottom-home {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  gap: 2vh;
  margin-top: 2vh;
}

.banner-home {
  width: 150px;
  height: 150px;
}

.games-container-home {
  height: 65%;
  width: 80%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10vw;
  padding-right: 10vw;
  margin-top: 5vh;
}

.label-section-title-games-home {
  font-weight: 800;
  font-size: 2.5rem;
  margin-left: 1vw;
  color: var(--dark-blue);
}

.label-section-title-home {
  font-weight: 800;
  font-size: 2rem;
  margin-left: 1vw;
  color: var(--dark-blue);
  display: flex;
  justify-content: center;
}

.games-home:hover .navigateBefore,
.games-home:hover .navigateNext {
  opacity: 1;
}

.allgames-home {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5vh;
  transition: all ease 0.2s;
  overflow: hidden;
  flex-wrap: wrap;
}

.games-home {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5vh;
  transition: all ease 0.2s;
  overflow: hidden;
}

.other-games-container-home {
  width: 100%;
  height: 45%;
  display: flex;
  flex-direction: row;
  padding-left: 10vw;
  padding-right: 10vw;
  overflow: hidden;
  gap: 2vw;
}

.recommended-games-home {
  height: 100%;
  width: 39%;
  overflow-y: hidden;
  overflow-x: hidden;
}

.favorites-games-home {
  height: 100%;
  width: 39%;
  overflow-y: hidden;
  overflow-x: hidden;
}

.label-no-games-home {
  width: 100%;
  height: 50%;
  font-weight: 800;
  font-size: 1.5rem;
  margin-left: 1vw;
  color: var(--dark-blue);
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-container-home {
  height: 10%;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 5vw;
}

.footer-inputs-home {
  height: 100%;
  width: 40%;
  margin-left: 1.5vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.footer-selects-home {
  box-sizing: border-box;
  outline: none;
  border: none;
  width: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  border-radius: 5px;
  font-size: 18px;
  background-color: #f0f2f5;
}

.footer-button-home {
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1d3557;
  padding: 15px;
  border-radius: 10px;
  color: #f5f5f5;
  font-weight: 500;
  font-size: 25px;
  cursor: pointer;
  transform: scale(0.95);
  transition: all ease 0.2s;
}

.footer-button-home:hover {
  transform: scale(1);
}

.background-right-home {
  width: 30%;
  display: flex;
  flex-direction: column;
}

.option-buttons-container-home {
  width: 100%;
  height: 15%;
  display: flex;
  justify-content: flex-end;
}

.option-button-box-home {
  height: fit-content;
  color: #1d3557;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 2vw;
  margin-top: 2vh;
  cursor: pointer;
}

.option-image-home {
  width: 40px;
  height: 40px;
}

.option-image-description {
  cursor: pointer;
}
