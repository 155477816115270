.background-confirmation-modal {
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content-confirmation-modal {
  background-color: #fff;
  border-radius: 10px;
  max-width: 280px;
  padding: 0.5%;
}

.header-confirmation-modal {
  width: 100%;
  height: 5%;
  display: flex;
  justify-content: flex-end;
}

.header-confirmation-modal label {
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 600;
  color: #c9c9c9;
  margin-right: 0.5vw;
  transition: all ease 0.3s;
}

.header-confirmation-modal label:hover {
  color: #e74c3c;
  transform: scale(1.1);
}

.image-container-confirmation-modal {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.image-confirmation-modal {
  width: 50%;
  height: auto;
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.title-confirmation-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 12%;
  color: var(--blue);
}

.title-confirmation-modal h2 {
  font-size: 1.5rem;
  font-weight: 600;
}

.message-confirmation-modal {
  width: 100%;
  margin-top: 0.5vh;
  margin-bottom: 2vh;
}

.message-confirmation-modal p {
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
}

.buttons-confirmation-modal {
  width: 100%;
  display: flex;
  justify-content: center;
}

.button-confirm-confirmation-modal,
.button-cancel-confirmation-modal {
  width: 100%;
  padding: 8px 16px;
  margin: 0 10px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  background-color: #c9c9c9;
  color: #000;
  transition: all ease 0.3s;
}

.button-confirm-confirmation-modal:hover,
.button-cancel-confirmation-modal:hover {
  transform: scale(1.1);
}

/* .button-confirm-confirmation-modal {
  background-color: #1d3557;
  color: #fff;
}

.button-cancel-confirmation-modal {
  border: 2px solid #e74c3c;
  background-color: #fff;
  color: #e74c3c;
  transition: all ease 0.3s;
}

.button-cancel-confirmation-modal:hover {
  background-color: #e74c3c;
  color: #fff;
} */
