.container-loading {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1vh;
}

.container-loading label {
  font-size: 1.2rem;
  color: var(--dark-blue);
}

.loader {
  border: 5px solid var(--gray);
  border-top: 5px solid var(--dark-blue);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
