.background-games {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: #F8F8F8;;
}

.header-container-games {
    width: 100%;
    height: 10%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: #1D3557;
}

.input-container-games {
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1vw;
}

.input-games {
    box-sizing: border-box;
    outline: none;
    border: none;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    width: 45%;
    height: 45%;
    border-radius: 10px;
    font-size: 16px;
    background-color: #F0F2F5;
    margin-top: 5px;
    margin-bottom: 15px;
    align-self: center;
    justify-self: center;
}

.label-go-back-games {
    width: 30%;
    height: 100%;
    gap: 0.5vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #F0F2F5;
    cursor: pointer;
}

.data-container-games {
    width: 100%;
    height: 85%;
    display: flex;
    flex-direction: row;
}

.games-container-games {
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    overflow: auto;
}

.games-container-games::-webkit-scrollbar {
  width: 10px;
}

.games-container-games::-webkit-scrollbar-thumb {
  background-color: #1D3557;
  border-radius: 20px;
}

/* .filter-container-games {
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.filter-container-games button {
    width: 50px;
    height: 50px;
    cursor: pointer;
    background: transparent;
    border: 0;
    border-radius: 8px;
    color: #1D3557;
}
 */


.filter-disabled-games{
    display: none;
}