.container-input {
  position: relative;
  margin-top: 1vh;
  margin-bottom: 1vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.container-input input {
  width: 100%;
  border: 1px solid var(--blue);
  border-radius: 0.4rem;
  padding: 0.7rem;
  font-size: 1.1rem;
}

.container-input input:focus {
  border: 1px solid var(--dark-blue);
}

.container-input label {
  position: absolute;
  width: 90%;
  top: 50%;
  transform: translateY(-50%);
  left: 0.5rem;
  padding: 0px 0.4rem 0px 0.4rem;
  background-color: white;
  color: var(--blue);
  transition: all ease-in-out 0.12s;
  cursor: text;
}

.container-input input:focus + label,
.container-input input:not(:placeholder-shown) + label,
.container-input input:valid + label {
  width: auto;
  color: var(--dark-blue);
  top: 1%;
  font-size: 0.9rem;
  font-weight: 600;
}

.container-input input:not(:focus) + label {
  color: var(--blue);
  font-weight: 500;
}
