.container-game-modal {
  position: absolute;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.data-game-modal {
  background-color: var(--gray);
  height: 60vh;
  width: 30vw;
  min-width: 500px;
  border-radius: 16px;
}

.container-close-game-modal {
  position: absolute;
  width: 30vw;
  display: flex;
  flex-direction: column;
  align-items: end;
}

.close-game-modal {
  color: var(--dark-gray);
  cursor: pointer;
  height: 2rem;
  width: 2rem;
  padding-right: 0.4vw;
  padding-top: 0.8vh;
  transform: scale(1);
  transition: all ease 0.2s;
}

.close-game-modal:hover {
  cursor: pointer;
  color: var(--dark-blue);
  transform: scale(1.1);
}

.image-container-game-modal {
  height: 50%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.game-image-game-modal {
  height: 82%;
  width: 88%;
  margin-top: 1.3vh;
  border-radius: 16px;
}

.info-game-modal {
  height: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.main-info-game-modal {
  height: 35%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.title-game-modal {
  font-weight: 800;
  font-size: 1.3rem;
  color: var(--dark-blue);
  padding-left: 1vw;
}

.description-game-modal {
  font-size: 1.1rem;
  padding-left: 1vw;
}

.other-info-game-modal {
  height: 35%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.players-container-game-modal {
  height: 100%;
  width: 70%;
  display: flex;
  flex-direction: column;
}

.player-icons-container-game-modal {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 0.5vw;
  padding-top: 1vh;
  padding-left: 1vw;
}

.favorite-container-game-modal {
  height: 100%;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.favorite-container-game-modal .title-game-modal {
  padding-left: 0;
}

.button-favorite-game-modal {
  cursor: pointer;
}

.button-play-container-game-modal {
  height: 30%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-play-game-modal {
  padding: 1vh 2vw;
  line-height: 40px;
  display: flex;
  justify-content: center;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: var(--text-white);
  background-image: linear-gradient(
    to right,
    var(--dark-blue),
    var(--light-blue)
  );
  font-weight: 700;
  font-size: 1.4rem;
  text-transform: uppercase;
  letter-spacing: 0.1vw;
  transform: scale(1);
  transition: all ease 0.2s;
}

.button-play-game-modal:hover {
  cursor: pointer;
  transform: scale(1.1);
}
