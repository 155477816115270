.background-admin {
  background-color: var(--background-white);
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
}

.background-right-admin {
  height: 100vh;
  width: 70vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-admin {
  width: 35%;
  border-radius: 10px;
  padding: 20px;
  padding-top: 0px;
  background-color: white;
  filter: drop-shadow(3px 3px 6px darkgrey);
  display: flex;
  flex-direction: column;
  gap: 1vh;
}

.content-admin h1 {
  font-size: 24px;
  font-weight: 500;
  color: var(--dark-blue);
  margin: 0.5vh 0 0.5vh 0;
  align-self: center;
}

.button-admin {
  height: 100%;
  line-height: 40px;
  width: 100%;
  align-self: center;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  background-image: linear-gradient(to left, var(--dark-blue), var(--blue));
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
