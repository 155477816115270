.list-area-game-row {
  overflow: hidden;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
}

.list-area-game-row:hover .navigate-before-game-row,
.list-area-game-row:hover .navigate-next-game-row {
  opacity: 1;
}

.list-game-row {
  height: 100%;
  display: flex;
  flex-direction: row;
  transition: all ease 0.4s;
}

.navigate-before-game-row,
.navigate-next-game-row {
  position: absolute;
  width: 35px;
  height: 100%;
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  opacity: 0;
  transition: all ease 0.6s;
}

.navigate-before-game-row:hover,
.navigate-next-game-row:hover {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.9);
}

.navigate-before-game-row {
  left: 0;
}

.navigate-next-game-row {
  right: 0;
}
