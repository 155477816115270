.background-students {
  height: 100vh;
  width: 100vw;
  position: absolute;
  display: flex;
  flex-direction: row;
}

.background-right-students {
  height: 100vh;
  width: 70vw;
  background-color: #f0f2f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container-header-students {
  height: 5%;
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
}

.options-header-students {
  color: var(--light-blue);
  width: 3%;
  height: auto;
  margin-top: 1vh;
  margin-right: 0.5vw;
  cursor: pointer;
}

.options-container-students-enable {
  background-color: var(--light-blue);
  width: auto;
  position: absolute;
  top: 0;
  margin-top: 0.8vh;
  margin-right: 0.5vw;
  border-radius: 10px;
}

.options-container-students-disable {
  display: none;
}

.option-students {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5vw;
  margin: 0.8rem;
}

.option-students,
.option-students label {
  color: var(--text-white);
  cursor: pointer;
}

.option-image-students {
  width: 25px;
  height: 25px;
}

.container-students {
  height: 95%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-content-students {
  min-height: 30vh;
  max-height: 70vh;
  width: 25vw;
  background-image: linear-gradient(to bottom, var(--light-blue), var(--blue));
  filter: drop-shadow(0px 0px 6px darkgrey);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.label-title-students {
  color: var(--text-white);
  height: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3vh;
  font-weight: 800;
}

.content-students {
  min-height: fit-content;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  gap: 1vw;
  overflow: auto;
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.content-students::-webkit-scrollbar {
  width: 8px;
}

.content-students::-webkit-scrollbar-thumb {
  background-color: #1d3557;
  border-radius: 20px;
}

.label-no-students {
  height: 70%;
  font-size: 100%;
  font-weight: 400;
  text-transform: uppercase;
  align-self: center;
  margin-top: 1vh;
  margin-bottom: 1vh;
  padding-left: 1vw;
  padding-right: 1vw;
  text-align: center;
  color: var(--text-white);
}

.container-arrows-students {
  height: 15%;
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--text-white);
}

.arrows-students {
  scale: 1;
  transition: all ease 0.2s;
  margin: 0.5vw;
  cursor: pointer;
}

.arrows-students:hover {
  scale: 1.2;
}

.arrows-students-disable {
  display: none;
}

@media screen and (max-width: 480px) {
  .background-students {
    flex-direction: column;
  }
  .background-left-students {
    height: 30vh;
    width: 100vw;
  }
  .background-right-students {
    height: 70vh;
    width: 100vw;
  }
  .container-students {
    min-width: 240px;
  }
}
