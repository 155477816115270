.container-player-card {
  height: 5vh;
  width: 5vh;
  display: flex;
}

.image-player-card {
  height: 100%;
  width: 100%;
  border-radius: 16px;
}
