.background-status-modal {
  position: absolute;
  z-index: 101;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content-status-modal {
  background-color: #fff;
  border-radius: 10px;
  max-width: 280px;
  padding: 1vh 1vw;
}

.image-container-status-modal {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.image-status-modal {
  width: 50%;
  height: auto;
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.title-status-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 12%;
  color: var(--blue);
}

.title-status-modal h2 {
  font-size: 1.5rem;
  font-weight: 600;
}

.message-status-modal {
  width: 100%;
  margin-top: 0.5vh;
  margin-bottom: 2vh;
}

.message-status-modal p {
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
}

.buttons-status-modal {
  width: 100%;
  display: flex;
  justify-content: center;
}

.button-confirm-status-modal {
  width: 100%;
  padding: 8px 16px;
  margin: 0 10px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  background-color: #c9c9c9;
  color: #000;
  transition: all ease 0.3s;
  margin-bottom: 1vh;
}

.button-confirm-status-modal:hover {
  transform: scale(1.1);
}
