@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins/Poppins-Medium.ttf");
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  margin: 0;

  --dark-blue: #002c6a;
  --blue: #0764a3;
  --light-blue: #0c9fe0;
  --dark-gray: #c9c9c9;
  --gray: #e7e8e9;
  --light-gray: #f4f4f4;

  --text-white: #ffffff;

  --background-white: #f0f2f5;
}
