.background-logo {
  height: 100%;
  width: 30%;
  background-image: linear-gradient(
    to right,
    var(--dark-blue),
    var(--light-blue)
  );
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.exit-icon-background-logo {
  position: absolute;
  width: 2%;
  height: auto;
  top: 2%;
  left: 1%;
  color: var(--background-white);
  transform: scale(0.9);
  transition: all ease 0.2s;
}

.exit-icon-background-logo:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.text-normal-background-logo {
  width: 100%;
  text-align: center;
  font-size: 1.8rem;
  font-weight: 500;
  color: #ffffff;
}

.text-bold-background-logo {
  width: 100%;
  text-align: center;
  font-size: 2.5rem;
  font-weight: 800;
  color: #ffffff;
}

.background-image {
  width: 70%;
  margin-bottom: 5vh;
}

.logo-image {
  width: 30%;
}
