.backgroundSignup {
  height: 100vh;
  width: 100vw;
  position: absolute;
  display: flex;
  flex-direction: row;
}

.background-rightSignup {
  height: 100vh;
  width: 70vw;
  background-color: var(--background-white);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.logoImage {
  width: 50%;
}

.contentSignup {
  width: 30%;
  min-width: 280px;
  border-radius: 10px;
  padding: 20px;
  background-color: white;
  filter: drop-shadow(0px 0px 6px darkgrey);
  display: flex;
  flex-direction: column;
}

.labelTitleSignup {
  align-self: center;
  font-size: 1.5rem;
  font-weight: 800;
  margin: -0.5rem 0 0.5rem 0;
  text-transform: uppercase;
  color: var(--dark-blue);
}

.labelErrorSignup {
  font-size: 14px;
  font-weight: 600;
  color: red;
  margin-bottom: 10px;
}

.buttonsContainerSignup {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.buttonSignup,
.buttonVoltarSignup {
  height: 100%;
  line-height: 40px;
  width: 100%;
  align-self: center;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: var(--text-white);
  background: var(--dark-blue);
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  transform: scale(0.97);
  transition: all ease 0.2s;
}

.buttonSignup:hover,
.buttonVoltarSignup:hover {
  transform: scale(1.05);
}

@media screen and (max-width: 480px) {
  .backgroundSignup {
    flex-direction: column;
  }
  .background-leftSignup {
    height: 30vh;
    width: 100vw;
  }
  .background-rightSignup {
    height: 70vh;
    width: 100vw;
  }
  .contentSignup {
    min-width: 240px;
  }
}
