.background-game-card {
    height: 150px;
    width: 300px;
    border-radius: 10px; 
    transform: scale(0.95);
    cursor: pointer;
    transition: all ease 0.2s;
    border: 6px solid #1D3557;
}

.background-game-card:hover {
    transform: scale(1);
}

.image-game-card {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}