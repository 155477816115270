.background-signin {
  height: 100vh;
  width: 100vw;
  position: absolute;
  display: flex;
  flex-direction: row;
}

.background-left-signin {
  height: 100%;
  width: 50%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: -3vh;
}

.background-right-signin {
  height: 100vh;
  width: 50vw;
  background-image: linear-gradient(to left, var(--dark-blue), var(--blue));
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.left-text-signin {
  width: 50%;
  text-align: center;
  font-size: 1.8rem;
  font-weight: 600;
  color: var(--blue);
}

.left-text-signin strong {
  font-size: 2rem;
  font-weight: 800;
  color: var(--dark-blue);
}

.left-image-signin {
  width: 55%;
  transition: all ease 0.8s;
  margin-top: -1vh;
  margin-bottom: -1vh;
}

.institution-logo-signin {
  position: absolute;
  bottom: 0;
}

.image-options-container-signin {
  width: 10%;
  height: 3%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 10%;
}

.image-option-selected-signin {
  width: 20%;
  height: 70%;
  background-color: #013573;
  border-radius: 360px;
}

.image-option-signin {
  width: 20%;
  height: 70%;
  background-color: #c4c4c4;
  border-radius: 360px;
}

.content-signin {
  width: 40%;
  min-width: 280px;
  border-radius: 10px;
  padding: 20px;
  padding-top: 0px;
  background-color: white;
  filter: drop-shadow(3px 3px 6px darkgrey);
  display: flex;
  flex-direction: column;
  gap: 0.5vh;
}

.input-signin {
  box-sizing: border-box;
  outline: none;
  border: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  width: 100%;
  border-radius: 5px;
  font-size: 16px;
  background-color: #f0f2f5;
  margin-bottom: 15px;
}

.logo-image-signin {
  align-self: center;
}

.label-signin {
  margin-bottom: 0.5vh;
  color: #013573;
}

.label-error-signin {
  font-size: 14px;
  font-weight: 600;
  color: red;
  margin-bottom: 10px;
}

.button-signin {
  height: 100%;
  line-height: 40px;
  width: 100%;
  align-self: center;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  background-image: linear-gradient(to left, var(--dark-blue), var(--blue));
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.bottom-content-signin {
  position: absolute;
  bottom: 0;
  margin-bottom: 1vh;
}

.bottom-label-signin {
  color: #1d3557;
}

@media screen and (max-width: 480px) {
  .background-signin {
    flex-direction: column;
  }
  .background-left-signin {
    height: 30vh;
    width: 100vw;
  }
  .background-right-signin {
    height: 70vh;
    width: 100vw;
  }
  .content-signin {
    min-width: 240px;
  }
}
