.list-area-all-games-row {
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
}

.list-area-all-games-row:hover .navigate-before-all-games-row,
.list-area-all-games-row:hover .navigate-next-all-games-row {
  opacity: 1;
}

.container-list-all-games-row {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.list-all-games-row {
  height: 50%;
  display: flex;
  flex-direction: row;
  transition: all ease 0.4s;
}

.navigate-before-all-games-row,
.navigate-next-all-games-row {
  position: absolute;
  width: 3%;
  height: 100%;
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  opacity: 0;
  transition: all ease 0.6s;
}

.navigate-before-all-games-row:hover,
.navigate-next-all-games-row:hover {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.9);
}

.navigate-before-all-games-row {
  left: 0;
}

.navigate-next-all-games-row {
  right: 0;
}
